import { Component, OnInit } from '@angular/core';
declare let paypal :any;

@Component({
  selector: 'app-aboutpage',
  templateUrl: './aboutpage.component.html',
  styleUrls: ['./aboutpage.component.css']
})
export class AboutpageComponent  {
 
}
