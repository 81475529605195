import { FarmtourComponent } from './farmtour/farmtour.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutpageComponent } from './aboutpage/aboutpage.component';
import { AdminGuard } from './admin/admin.guard';
import { MainpageComponent } from './mainpage/mainpage.component';

export const routes: Routes = [


  {
    path : 'admin',
    loadChildren : './admin/admin.module#AdminModule',
    canActivate:[AdminGuard]
  },
  {
    path : '',
    loadChildren : './user/user.module#UserModule'
  },
  {
    path:"about", component :AboutpageComponent
  },
  {
    path:"farmtour", component:FarmtourComponent
  },
  {
    path:"mainpage", component:MainpageComponent
  }

];


export const navigatableComponents =[]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
