import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {Signup} from './signUp';
import { catchError, retry, map } from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import { User } from './user';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor( private http: HttpClient, private messageService: MessageService) { }
  baseUrl = environment.baseUrl;

  postHeader(){
    let headers= new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return headers;
  }

  toastMessage(type, msg) {
    let status = type == 1 ? 'success' : 'error';
    this.messageService.add({ severity: status, summary: 'Message Info', detail: msg });
    }
    private handleError(error:HttpErrorResponse){
      if(error.error instanceof ErrorEvent) {
      console.error('An error Occured : ', error.error.message);
      } else {
      console.error('Backend ERROR : ', error.error);
      }
      return throwError(error);
      }
  
    signup(signUp){
     let url = `${this.baseUrl}profiles`;
     let headers = this.postHeader();
      return this.http.post(url, signUp, { headers:headers});
    }
  
    login(user:User):Observable<User>{
      let url = `${this.baseUrl}profiles/login`;
      let headers = this.postHeader();
      return this.http.post<User>(url, user, {headers:headers} )
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
    }
    getUserById(id){
      let url = `${this.baseUrl}profiles/?filter[where][id]=${id}`;
      return this.http.get(url).pipe(retry(),catchError(this.handleError));
    }
    resetPassword(email) {
      let url = `${this.baseUrl}profiles/reset`;
      let request = {
        'email': email
      };
      let headers = new HttpHeaders();
      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");
      return this.http.post(url, request, { headers: headers })
        .pipe( catchError(this.handleError))
    }


  logout() {
    let id = (localStorage.getItem("maharshi_token"));
    if (!id) return;
    let url = `${this.baseUrl}profiles/logout?access_token=` + id;
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post(url,{ headers: headers });
  }


  getProfilebyMail(email){
    let url = `${this.baseUrl}profiles/?filter[where][email]=${email}`;
    return this.http.get(url).pipe(retry(),catchError(this.handleError));
  }

  }
