import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment.prod";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { UserAddress } from './myprofile/user-address';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class UserMaharshiService {
  private apiUrl = 'http://localhost:3012/api/';
  // private apiUrl = 'http://52.4.153.177:3012/api/';
    get(arg0: string) {
        throw new Error('Method not implemented.');
    }
    post(arg0: string, arg1: {}) {
        throw new Error('Method not implemented.');
    }
  emitChange$: Subject<any> = new BehaviorSubject<any>(null);
  emit(value: any) {
    this.emitChange$.next(value);
  }
  get emitChange(): BehaviorSubject<any> {
    return (this.emitChange$ as BehaviorSubject<any>);
  }

  nameEdit$: Subject<any> = new BehaviorSubject<any>(null);
  emitName(value: any){
    this.nameEdit$.next(value);
  }
  get nameEdit():BehaviorSubject<any>{
    return (this.nameEdit$ as BehaviorSubject<any>);
  }

  categoryChange$: Subject<any> = new BehaviorSubject<any>(null);
  emitcategory(value: any) {
    this.categoryChange$.next(value);
  }
  get categoryChange(): BehaviorSubject<any> {
    return (this.categoryChange$ as BehaviorSubject<any>);
  }

  loginPopup$: Subject<any> = new BehaviorSubject<any>(null);
  emitPopup(value: any) {
    this.loginPopup$.next(value);
  }
  get LoginPopup(): BehaviorSubject<any> {
    return (this.loginPopup$ as BehaviorSubject<any>);
  }

  logoutEmitter$: Subject<any> = new BehaviorSubject<any>(null);
  emitLogout(value: any) {
    this.logoutEmitter$.next(value);
  }
  get logoutEmitter(): BehaviorSubject<any> {
    return (this.logoutEmitter$ as BehaviorSubject<any>);
  }
  
  loginEmitter$: Subject<any> = new BehaviorSubject<any>(null);
  emitLogin(value: any) {
    this.loginEmitter$.next(value);
  }
  get loginEmitter(): BehaviorSubject<any> {
    return (this.loginEmitter$ as BehaviorSubject<any>);
  }
  baseUrl = environment.baseUrl

  constructor(private http: HttpClient, private messageservice: MessageService) { }
  postHeaders() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    return headers;
  }

  toastMessage(type, msg) {
    let status = type == 1 ? 'success' : 'error';
    this.messageservice.add({ severity: status, summary: 'Message Info', detail: msg });
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error Occured : ', error.error.message);
    } else {
      console.error('Backend ERROR : ', error.error);
    }

    return throwError('Something bad happened; Please try again');
  }
  gettingData(id) {
    // let url = `${this.baseUrl}orders?filter[include]=products`;
    let url = `${this.baseUrl}orders?filter[where][profileId]=` + id;
    //console.log(url);
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  ratingToProduct(rating, productid,id, userid, username, timestamp) {
    let url = `${this.baseUrl}ratings`;
    let body = {
      rating: rating,
      productsId: productid,
      orderProductsId: id,
      profileId: userid,
      profile_name: username,
      timestamp: timestamp
    }
    let headers = this.postHeaders();

    return this.http.post(url, body, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
  }

  gettingratingData() {
    let url = `${this.baseUrl}ratings`;
    //console.log(url);
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }
  patchProduct(rating, id) {
    //console.log('&&&&& patcdtyuiookjhjgh')
    let url = `${this.baseUrl}ratings/`;
    //console.log(url);
    let data = ({
      rating: rating,
      id: id
    })
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.patch(url, data, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }
  gettingCategories() {
    let url = `${this.baseUrl}categories?filter[include]=subcategories`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getProductsByCategory(category) {
    //console.log(category);
    let url = `${this.baseUrl}products?filter[where][subcategoriesId]=${category}` ;
    // let url = `${this.baseUrl}products`;
    //console.log("@@@@@@@")
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  getUserProfile(id) {
    //console.log("profile id:",id);
    let url = `${this.baseUrl}profiles?filter[where][id][eq]=` + id;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  updateProfile(data, id, token) {
    let url = `${this.baseUrl}profiles/${id}?access_token=${token}`;
    let headers = this.postHeaders();
    return this.http.patch(url, data, { headers: headers });
  }

  addNewAddresses(useraddress: UserAddress, addressId?) {
    let url;
    let headers = this.postHeaders();
    if (addressId) {
      url = `${this.baseUrl}addresses/${addressId}`;
      return this.http.patch(url, useraddress, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError))
    } else {
      url = `${this.baseUrl}addresses`;
      return this.http.post(url, useraddress, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError))
    }
  }
  getAddresses(id) {
    let url = `${this.baseUrl}addresses?filter[where][profileId]=` + id;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  gettingAllRatings(id?) {
    let url;
    if(id){
     url = `${this.baseUrl}ratings?filter[where][profileId]=`+id;
    }else{
     url = `${this.baseUrl}ratings`;
    }
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  getAllRatings()
  {
    let url = `${this.baseUrl}ratings/productRating`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getProductbyId(productId) {
    let url = `${this.baseUrl}products?filter[where][id]=` + productId;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  deleteAddedAddress(id, token) {
    let url = `${this.baseUrl}addresses/${id}?access_token=${token}`;
    return this.http.delete(url)
      .pipe(map(result => result));
    // .pipe(
    // retry(1),
    // catchError(this.handleError)
    // )
  }

  changePassword(token, oldPassword, newPassword) {
    let url = `${this.baseUrl}profiles/change-password/?access_token=${token}`;
    let body = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    let headers = this.postHeaders();
    return this.http.post(url, body, { headers: headers });
  }

  getProductsbysubCategory(sub_cat) {
    let url = `${this.baseUrl}products?filter[where][sub_category]=` + sub_cat;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  gettingAllProductss() {
    let url = `${this.baseUrl}products`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  postOrder(data){
    let url = `${this.baseUrl}orders`;
    let headers = this.postHeaders();
    return this.http.post(url, data, {headers:headers}).pipe(
      catchError(this.handleError)
    )
  }
  postOrderProducts(data){
    let url = `${this.baseUrl}order_products`;
    let headers = this.postHeaders();
    return this.http.post(url, data, {headers:headers}).pipe(
      catchError(this.handleError)
    )
  }
  getOrderProductscount(userId,productId)
  {
    let status = "draft";
    let url = `${this.baseUrl}order_products?filter[where][status]=${status}&filter[where][profileId]=${userId}&filter[where][productsId][like]=${productId}` ;
     
    return this.http.get(url)
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
  }

getOrderProductsbyUsers(profileId, status?){
  let url;
  if(status){
   url = `${this.baseUrl}order_products?filter[where][status]=` + status + `&filter[where][profileId]=` + profileId;}
   else{
    url = `${this.baseUrl}order_products?filter[where][profileId]=` + profileId + `&filter[where][status][neq]=draft`;
   }
  return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
}
getClientToken() {
 
  return this.http.get(`${this.apiUrl}client_token`);
}
// let url = `${this.baseUrl}order_products/${id}`;
// return this.http.delete(url)
// .pipe(
// catchError(this.handleError)
// )
checkout(paymentMethodNonce: string, amount: number) {
  return this.http.post(`${this.baseUrl}/checkout`, { paymentMethodNonce, amount });
}


initiatePayment(orderDetails: any) {
  return this.http.post(`${this.baseUrl}/initiate_payment`, orderDetails);
}
 
getOrdersByUser(profileId, status?){
  let url;
  if(status){
   url = `${this.baseUrl}orders?filter[where][status]=` + status + `&filter[where][profileId]=` + profileId;}
   else{
    url = `${this.baseUrl}orders?filter[where][profileId]=` + profileId+`&filter[where][status][neq]=draft`;
   }
  return this.http.get(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

deleteOrderbyId(id) {
  let url = `${this.baseUrl}order_products/${id}`;
  return this.http.delete(url)
  .pipe(
  catchError(this.handleError)
  )
}

quantityofCartItemsbyId(id, data){
  let url = `${this.baseUrl}order_products/${id}`;
    let headers = this.postHeaders();
    return this.http.patch(url, data, { headers: headers })
    .pipe(
      catchError(this.handleError)
    )
}

placeOrder(profileId, data){
  let url = `${this.baseUrl}orders/update?where[status]=draft&[profileId]=` + profileId;
  let headers = this.postHeaders();
  return this.http.post(url, data, { headers: headers }).pipe(
    catchError(this.handleError)
  )
}

updateOrderStatusonPayment(profileId, data){
  let url = `${this.baseUrl}order_products/update?where[status]=draft&[profileId]=` + profileId;
    let headers = this.postHeaders();
    return this.http.post(url, data, { headers: headers }).pipe(
      catchError(this.handleError)
    )
}

getProductsByProductNameGroup(name){

  let url = `${this.baseUrl}products?filter[where][product_name][eq]=` + name ;
  return this.http.get(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}
sendmail(message) {
  let url = `${this.baseUrl}orders/sendContactEmail`;
  let headers = this.postHeaders();
  return this.http.post(url, message, { headers: headers })
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
}


encryptdata(request){
  let url = `${this.baseUrl}orders/encryptFormData`;
  let data = {
  request : request
  }
  return this.http.get(url,{params:data})
}

userWishlistProduct(data) {
  let url = `${this.baseUrl}wishlists`;
    let headers = this.postHeaders();
    return this.http.post(url, data, { headers: headers }).pipe(
      catchError(this.handleError)
    )
}
checkProductInWishList(userid,productid)
{
 
  let url = `${this.baseUrl}wishlists?filter[where][profileId]=${userid}&filter[where][productId]=${productid}` ;
  return this.http.get(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

deleteWishlistProduct(id)
{
  let url = `${this.baseUrl}wishlists/${id}` ;
  return this.http.delete(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}
getUserWishlistProducts(pfofileId) {
  let url = `${this.baseUrl}wishlists?fliter[where][profileId]=$mj
  {pfofileId}&filter[include]=products`;
  return this.http.get(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
}
getUserWishListData(userid)
{
  let url = `${this.baseUrl}wishlists?filter[where][profileId]=${userid}` ;
  return this.http.get(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}
updateProductsQuantity(d)
{
  let data={
    cartProducts:d
  }
  let url = `${this.baseUrl}products/quantityUpdate`;
  let headers = this.postHeaders();
  return this.http.post(url, data, { headers: headers }).pipe(
    catchError(this.handleError)
  )
}
getCatSimilarProducts(value,pr_id){
  let url = `${this.baseUrl}products?filter[where][category_name]=${value}&filter[where][id][neq]=${pr_id}&filter[limit]=4`;
  return this.http.get(url).pipe(catchError(this.handleError));
}
getProductOriginalQuantity(id)
{
  let url = `${this.baseUrl}products?filter[where][id]=${id}` ;
  return this.http.get(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}

gettingFilterProducts(data) {
  let url = `${this.baseUrl}products/productsFilters`;
  let headers = this.postHeaders();
  return this.http.post(url, data, { headers: headers }).pipe(
    catchError(this.handleError));
}
updateRating(data, id) {
  let url = `${this.baseUrl}products/${id}`;
  let headers = this.postHeaders();
  return this.http.patch(url, data, { headers: headers });
}
gettingMinMaxPrice(id) {
  let url = `${this.baseUrl}products/productsMaxMinPrice`;
  let data =
  {
    Id: id,
   };
  let headers = this.postHeaders();
  return this.http.post(url, data, { headers: headers }).pipe(
    catchError(this.handleError));
}
gettingSizes(data)
{
  let url = `${this.baseUrl}products/getBrandSizes`;
  let headers = this.postHeaders();
  return this.http.post(url, data, { headers: headers }).pipe(
    catchError(this.handleError)
  )
}
getBrands(id)
{
  let url = `${this.baseUrl}brands?filter[where][subcategoriesId]=${id}` ;
  return this.http.get(url)
  .pipe(
    retry(1),
    catchError(this.handleError)
  )
}


  postReviews(data){
    let url = `${this.baseUrl}reviews/`;
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.patch(url, data, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }


  getReviews(){
    let url = `${this.baseUrl}reviews/` ;
    return this.http.get(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  getReviewsByProductId(id){
    let url = `${this.baseUrl}reviews/getReviewsByProductId/${id}` ;
    return this.http.get(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  getReviewsByProductIdOrderId(pds,oid){
    let url = `${this.baseUrl}reviews/getReviewsByProductIdOrderId/${pds}/${oid}` ;
    return this.http.get(url)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
}
