import { Component, ViewChild } from '@angular/core';
import { SharedService } from './shared/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Signup } from './shared/signUp';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { User } from './shared/user';
import { flatMap } from 'rxjs/operators';
import { UserMaharshiService } from './user/user-maharshi.service';
import { AdminMaharshiService } from './admin/admin-maharshi.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'chandhras';
  loginDialog: boolean = false;
  registerForm: FormGroup;
  loginForm: FormGroup;
  submitted = false;
  signup: any;
  signUp: Signup;
  isRegform: boolean = false;
  user: User;
  id: string;
  token: any;
  postlogins: any;
  userName: any;
  showMenu: boolean = false;
  display: boolean = false;
  forgotEmail: string;
  relam: any;
  cartItemsNo: number = 0;
  categories: any = [];
  spinner: boolean = true;
  catName: any;
  constructor(private formBuilder: FormBuilder, private service: SharedService,
    private router: Router, private messageService: MessageService, public userService: UserMaharshiService, private maharshiservice: AdminMaharshiService, private activeRoute: ActivatedRoute) {
    this.signup = new Signup();
    this.user = new User();
    userService.emitChange.subscribe(
      count => {
        this.cartItemsNo = count;
      });
    userService.nameEdit.subscribe(name => {
      this.userName = name;
    });

    userService.LoginPopup.subscribe(val => {
      if (val) {
        this.onLoginClick();
      }
    });

    userService.logoutEmitter.subscribe(val => {
      if (val) {
        this.logOut();
      }
    });
  }
  ngOnInit() {
    this.catName = this.activeRoute.snapshot.queryParamMap.get("category");
    this.userService.gettingCategories().subscribe(res => {
      this.categories = res;
      setTimeout(() => {
        this.spinner = false;
      }, 200);

    });
    this.userName = localStorage.getItem("userName");
    this.relam = localStorage.getItem("realm");
    if (localStorage.getItem("userId")) {
      this.getCartItemsCount();
    }
    this.registerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_no: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(5)]],
    });
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.isRegform ? this.registerForm.controls : this.loginForm.controls; }

  onLoginClick() {
    this.submitted = false;
    this.loginDialog = true;
    this.isRegform = false;
    this.signup = new Signup();
    this.user = new User();
    this.loginForm.reset();
  }

  onSubmit(values) {
    values.realm = "user";
    values.timestamp = new Date();
    this.service.signup(values)
      .subscribe(res => {
        this.service.toastMessage(1, 'User Registered Successfully');
        this.loginDialog = false;
      }, err => {
        console.log(err);
        this.service.toastMessage(0, 'Failed to Register');
      })
  }

  toggleform() {
    this.isRegform = !this.isRegform;
    this.signup = new Signup();
    this.user = new User();
    this.registerForm.reset();
    this.loginForm.reset();
    this.isLoginForgot = false;
  }

  login(data) {
    this.service.getProfilebyMail(data.email).subscribe(response => {
      let profileData: any = response;
      //console.log("login dataaaaa",profileData);
      if (profileData.length > 0 && profileData[0].status == 1) {
        this.submitted = true;
        this.service.login(data)
          .subscribe(res => {
            this.user = new User();
            this.postlogins = res;
            this.id = this.postlogins.userId;
            this.token = this.postlogins.id;
            localStorage.setItem("maharshi_token", this.token);
            localStorage.setItem("userId", this.id);
            this.relam = profileData[0].realm;
            localStorage.setItem("realm", this.relam);
            this.userName = profileData[0].first_name;
            localStorage.setItem("userName", profileData[0].first_name);
            this.getCartItemsCount();
            localStorage.setItem("userEmail", profileData[0].email);
            this.getCartItemsCount();
            if (localStorage.getItem("realm") == 'admin') {
              this.router.navigate(['/admin/adminorders']);
            }
            this.userService.emitLogin(true);
            this.loginDialog = false;
          }, error => {
            console.log(error);
            this.submitted = false;
            this.service.toastMessage(0, 'Invalid Credentials');
          }
          );
      } else {
        if (profileData.length > 0 && profileData[0].status == 0) {
          this.service.toastMessage(0, 'Your Account is Deactivated');
        }
        else {
          this.service.toastMessage(0, 'Please Register To Login');

        }

      }
    });
  }

  getCartItemsCount() {
    let status = 'draft';
    this.userService.getOrderProductsbyUsers(localStorage.getItem("userId"), status).subscribe(res => {
      let resp: any = res;
      this.cartItemsNo = resp.length;
    })
  }


  menuDisplay() {
    this.showMenu = !this.showMenu;

    
  }

  logOut() {
    this.service.logout().subscribe(res => {
      this.router.navigate(['']);
      localStorage.clear();
      this.userName = null;
      this.cartItemsNo = 0;
    }, error => {
      console.log("Error", error);
    });

  }
  forgotPW() {
    this.service.resetPassword(this.forgotEmail).subscribe(res => {
      this.service.toastMessage(1, 'Reset Password Link sent to your mail');
      this.display = false;
      this.isLoginForgot = false;
      this.forgotEmail = '';
    }, err => {
      this.service.toastMessage(0, err.error.error.message);
    });
  }
  myProfile() {
    this.router.navigate(['/myprofile', localStorage.getItem("userId")]);
  }

  myorders() {
    this.router.navigate(['/orders']);
  }
  allOrders() {
    if (this.relam == 'admin') {
      this.router.navigate(['/admin/adminorders']);
    }
  }
  ratings() {
    this.router.navigate(['/admin/review']);
  }
  usermanagement() {
    this.router.navigate(['/admin/usermanagement']);

  }
  masters() {
    this.router.navigate(['/admin/masters']);
  }
  media() {
    this.router.navigate(['/admin/media']);
  }
  uploadproducts() {
    this.router.navigate(['/admin/productsupload']);
  }
  couponmanagement() {
    this.router.navigate(['/admin/couponmanagement']);
  }
  reviews() {
    this.router.navigate(['/admin/review']);
  }
  home() {
    this.router.navigate(['/mainpage']);
  }
  goToCart() {

    if (localStorage.getItem("userId")) {
      if (this.cartItemsNo === 0) {
        this.userService.toastMessage(1, 'Your Cart Is Empty! Please Add Products');
      }
      else {
        this.router.navigate(['/viewcart']);

      }
    }
    else {
      this.onLoginClick();
    }

    let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }
  viewProducts(cat, subName, catName) {
    //console.log("routing::",cat,subName,catName);
    if (cat == this.router.url.split('=')[1]) {
      return;
    } else {
      localStorage.setItem('subid', '');
      let path = this.router.url.split('?')[0]
      this.router.navigate(['/products'], {
        queryParams: {
          subId: cat,
          subName: subName,
          catName: catName

        }
      });
      this.catName = cat;
      if (path == '/products') {
        this.userService.emitcategory(cat);
      }
    }
    let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }
  isLoginForgot: boolean = false;

  showforgot() {
    this.isLoginForgot = !this.isLoginForgot;
    this.forgotEmail = '';
  }
  selectedIndex: number;
  select(index: number) {
    this.selectedIndex = index;

  }
  abouts(){
    this.router.navigate(['/about'])
  }
  farmtour(){
    this.router.navigate(['/farmtour'])

  }
  contacts(){
    this.router.navigate(['/contactus'])
  }
  homes(){
    this.router.navigate(['/home'])
  }

  goToPage(pageName:any) {
    this.router.navigate([pageName]);
  }

}
