import { Injectable } from '@angular/core';
import { Media } from './media/media';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { Subject, timer, Subscription } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment.prod';
import { Product } from "./productsupload/product";

@Injectable({
  providedIn: 'root'
})
export class AdminMaharshiService {

  baseUrl = environment.baseUrl

  constructor(private http: HttpClient, private messageService: MessageService) { }
  postHeaders() {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return headers;
  }

  toastMessage(type, msg) {
    let status = type == 1 ? 'success' : 'error';
    this.messageService.add({ severity: status, summary: 'Message Info', detail: msg });
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error Occured : ', error.error.message);
    } else {
      console.error('Backend ERROR : ', error.error);
    }

    return throwError('Something bad happened; Please try again');
  }

  postNewImage(media: Media): Observable<Media> {
    let url = `${this.baseUrl}medialibraries`;
    let headers = this.postHeaders();
    return this.http.post<Media>(url, media, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  getImages(): Observable<Media[]> {
    let url = `${this.baseUrl}medialibraries`;
    return this.http.get<Media[]>(url)
      .pipe(
        retry(),
        catchError(this.handleError)
      )
  }

  DeleteImage(id) {
    let url = `${this.baseUrl}medialibraries/${id}`;
    return this.http.delete<Media[]>(url)
      .pipe(
        retry(),
        catchError(this.handleError)
      );
  }

  getproductreviews() {
    let url = `${this.baseUrl}products/getReviewedProducts`;
    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  getuserprofile(id) {
    // http://192.168.0.106:3000/api/profiles/5c9345b97993da065824ed0e

    let url = `${this.baseUrl}profiles/${id}`;
    return this.http.get(url)
      .pipe(
        retry(),
        catchError(this.handleError)
      );
  }


  deleteReview(id) {
    let url = `${this.baseUrl}reviews/${id}`;
    return this.http.delete(url).pipe(
      retry(),
      catchError(this.handleError)
    );

  }

  patchStatus(id, status) {
    let url = `${this.baseUrl}orders/` + id;
    let headers = this.postHeaders();
    return this.http.patch(url, status, { headers: headers })
      .pipe(
        catchError(this.handleError)
      )
  }

  patchOrderProductStatus(data, id) {
    let url = `${this.baseUrl}order_products/${id}`;
    let headers = this.postHeaders();
    return this.http.patch(url, data, { headers: headers }).pipe(
      catchError(this.handleError)
    )
  }

  gettingData() {
    let url = `${this.baseUrl}orders?filter[where][status][neq]=draft&filter[order]=timestamp DESC&filter[include]=profile`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  getFilterData(id, status) {
    let url = `${this.baseUrl}order_products?filter[where][status]=${status}&filter[where][ordersId]=${id}`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  sendmail(message) {
    let url = `${this.baseUrl}orders/sendOrderinMail`;
    let headers = this.postHeaders();
    return this.http.post(url, message, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  postproductdata(product): Observable<Product> {
    let url = `${this.baseUrl}products`;
    let headers = this.postHeaders();
    return this.http.post<Product>(url, product, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  gettingproductData() {
    let url = `${this.baseUrl}products`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )

  }

  deleteproduct(id) {
    let url = `${this.baseUrl}products/${id}`;
    return this.http.delete(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  editProduct(product: Product): Observable<Product> {
    let url = `${this.baseUrl}products`;
    let headers = this.postHeaders();
    return this.http.patch<Product>(url, product, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  gettingorderProductData() {
    let url = `${this.baseUrl}order_products`;
    return this.http.get(url)
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  getuserprofiles() {
    // let url='http://192.168.0.106:3007/api/profiles'
    let url = `${this.baseUrl}profiles`;
    return this.http.get(url)
      .pipe(
        retry(),
        catchError(this.handleError)
      );
  }

  updateUserProfiles(data, token) {
    // let url=`http://192.168.0.106:3007/api/profiles/${data.id}?access_token=${token}`;
    let url = `${this.baseUrl}profiles/${data.id}?access_token=${token}`;
    let headers = this.postHeaders();
    return this.http.patch(url, { "realm": data.realm, "status": data.status }, { headers: headers });
  }

  addNewCoupon(data) {
    let url = `${this.baseUrl}coupons`;
    let headers = this.postHeaders();
    return this.http.post(url, data, { headers: headers }).pipe(catchError(this.handleError));
  }

  postCategory(data) {
    let url = `${this.baseUrl}categories`;
    let headers = this.postHeaders();
    return this.http.post(url, data, { headers: headers }).pipe(catchError(this.handleError));
  }

  getAllCategories() {
    let url = `${this.baseUrl}categories`;
    return this.http.get(url);
  }

  updateCategory(fd, id) {
    let url = `${this.baseUrl}categories/${id}`;
    let headers = this.postHeaders();
    return this.http.patch(url, fd, { headers: headers })
  }

  deleteCategory(id) {
    let url = `${this.baseUrl}categories/deleteCatIncludes/${id}`;
    return this.http.delete(url, id)
  }

  postSubCategory(data) {
    let url = `${this.baseUrl}subcategories`;
    let headers = this.postHeaders();
    return this.http.post(url, data, { headers: headers }).pipe(catchError(this.handleError));
  }
  getAllSubCategories() {
    let url = `${this.baseUrl}subcategories?[filter][include]=categories`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  updatesubCategory(fd, id) {
    let url = `${this.baseUrl}subcategories/${id}`;
    let headers = this.postHeaders();
    return this.http.patch(url, fd, { headers: headers })
  }


  deleteSubCategory(id) {
    let url = `${this.baseUrl}subcategories/deleteSubIncludes/${id}`;
    return this.http.delete(url, id)
  }


  getAllSubCategoriesByCategoryId(id) {
    let url = `${this.baseUrl}subcategories/getAllSubCategoriesByCategoryId/${id}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  getAllBrandsBySubCategoryId(id) {
    let url = `${this.baseUrl}brands/getAllBrandsBySubCategoryId/${id}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  postBrands(data) {
    let url = `${this.baseUrl}brands`;
    let headers = this.postHeaders();
    return this.http.post(url, data, { headers: headers }).pipe(catchError(this.handleError));
  }

  getAllBrandsWithIncluds() {
    let url = `${this.baseUrl}brands/getData`;
    return this.http.get(url)
  }

  deleteBrands(id) {
    let url = `${this.baseUrl}brands/${id}`;
    return this.http.delete(url, id)
  }

  updateBrands(data) {
    let url = `${this.baseUrl}brands/${data.id}`;
    let headers = this.postHeaders();
    return this.http.patch(url, data, { headers: headers })
  }

  getData() {
    let url = `${this.baseUrl}categories/getData`;
    return this.http.get(url)
  }

  postDummy(data) {
    let url = `${this.baseUrl}products/postMulti`;
    let headers = this.postHeaders();
    return this.http.post<Product>(url, data, { headers: headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

}
