import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule }   from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {InputTextModule} from 'primeng/inputtext';
import {MessageService} from 'primeng/api';
import {SliderModule} from 'primeng/slider';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {PanelModule} from 'primeng/panel';
import {MenuModule} from 'primeng/menu';
import{ MatButtonModule } from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';
import { ButtonModule } from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import {TableModule} from 'primeng/table';
import {CardModule} from 'primeng/card';
import {SlideshowModule} from 'ng-simple-slideshow';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {RatingModule} from 'primeng/rating';
import {DialogService} from 'primeng/api';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  declarations: [],
  imports: [
      CommonModule,
      FormsModule,
      DropdownModule,
      InputTextModule,
      ReactiveFormsModule,
      CommonModule,
      ButtonModule,
      ConfirmDialogModule,
      TableModule,
      ToastModule,
      TooltipModule,
      SliderModule,
      DialogModule,
      CheckboxModule,
      RadioButtonModule,
      FileUploadModule,
      MessagesModule,
      MessageModule,
      PanelModule,
      CardModule,
      MenuModule,
      MatButtonModule,
      MatMenuModule,
      MenubarModule,
      CalendarModule,
      SlideshowModule,
      MatRadioModule,
      MatDialogModule,
      RatingModule,
      ProgressSpinnerModule  ],

  providers: [ ConfirmationService, MessageService, DialogService],
  exports: [
    CommonModule,
      FormsModule,
      DropdownModule,
      InputTextModule,
      ReactiveFormsModule,
      CommonModule,
      ButtonModule,
      ConfirmDialogModule,
      TableModule,
      ToastModule,
      TooltipModule,
      SliderModule,
      DialogModule,
      CheckboxModule,
      RadioButtonModule,
      FileUploadModule,
      MessagesModule,
      MessageModule,
      PanelModule,
      CardModule,
      MenuModule,
      MatButtonModule,
      MatMenuModule,
      MenubarModule,
      CalendarModule,
      SlideshowModule,
      MatRadioModule,
      MatDialogModule,
      RatingModule,
      ProgressSpinnerModule

  ]

})
export class SharedResourcesModule { 
  
}
