import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule} from 'primeng/table';
import { HttpClientModule } from '@angular/common/http';
import { SharedResourcesModule } from '../app/shared/shared-resources.module';
import { AdminGuard } from './admin/admin.guard';
import { AuthGuard } from './shared/auth.guard';
import {MatTabsModule,MatProgressSpinnerModule} from '@angular/material';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import { AboutpageComponent } from './aboutpage/aboutpage.component';
import { CarouselModule } from 'primeng/carousel';
import { MainpageComponent } from './mainpage/mainpage.component';
import { FarmtourComponent } from './farmtour/farmtour.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutpageComponent,
    MainpageComponent,
    FarmtourComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TableModule,
    SharedResourcesModule,
    HttpClientModule,
    MatTabsModule,
    MatBadgeModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CarouselModule
  ],
  providers: [AdminGuard,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
