export const environment = {
  production: true,

//  baseUrl:"http://52.4.153.177:3012/api/"

//  baseUrl:"http://localhost:3012/api/"
baseUrl:"https://52.4.153.177:3012/api/"

//  baseUrl:"http://192.168.0.161:3012/api/"

};
 